
export interface INavItem {
  
  text: string;
  icon: string;
  path?: string;
  expanded?: boolean,
  title?: string;
  visible?: boolean;
  disabled?: boolean
  premiumFeature?: boolean;
  items?: Array<{
    text: string;
    path: string;
    title?: string;
  }>;
  
}

export const navigationTreeSeed: INavItem[]  = [
  {
    text: "Instalaciones",
    icon: "info",
    path: "/sites",
    title: "Instalaciones",

  },
  {
    text: "Geolocalización",
    path: "/geolocation",
    icon: "map"
  },
  {
    text: "Informes",
    items: [
      {
        text: "Informe Mensual",
        path: "/informes",
        title: "Informe Mensual",
      }
    ],
    icon: "doc"
  },
  {
    text: "Ajustes",
    path: "/settings",
    icon: "preferences",
  },
  {
    text: "Eficiencia energética",
    icon: "chart",
    premiumFeature: true,
    items: [
      {
        text: "Gestión de excedentes",
        path: "/excedents-management",
        title: "Gestión de excedentes",
      },
      {
        text: "Facturación inteligente",
        path: "/smart-billing",
        title: "Facturación inteligente",
      },
      {
        text: "Algoritmos EE",
        path: "/algorithms-ee",
        title: "Algoritmos EE",
      },
      {
        text: "Activación de cargas",
        path: "/load-activation",
        title: "Activación de cargas",
      }
    ]
  },
  {
    text: "Grupos Energía",
    path: "/energy-groups",
    icon: "group",
    premiumFeature: true,
  },
  {
    text: "Movilidad eléctrica", 
    icon: "car",
    premiumFeature: true,
    items: [
      {
        text: "Vehículo eléctrico",
        path: "/vehicle-config-charger",
        title: "Vehículo",
      },
      {
        text: "Recarga VE",
        path: "/vehicle-battery",
        title: "Bateria",
      }

    ]
  },
  {
    text: "Climatización",
    path: "/climatization",
    icon: "repeat",
    premiumFeature: true,
  },
  {
    text: "Domótica",
    path: "/domotica",
    icon: "home",
    premiumFeature: true,
  },
  {
    text: "Exportación datos",
    path: "/export-data",
    icon: "export",
    premiumFeature: true,
  },
  ];
  export default navigationTreeSeed;
