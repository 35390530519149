<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer single-card ">
    <img :src=logo class="imagen_logo" />
    <div class="dx-card content bg-white">
      <div class="header">
        <div class="title">{{title}}</div>
        <div class="description">{{description}}</div>
      </div>
      <slot />
    </div>
  </dx-scroll-view>
</template>

<script setup lang="ts">
import DxScrollView from "devextreme-vue/scroll-view";
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

//Default login logo
let logo = require('@/assets/Infitech_logo.png');

// Login logo personalization by domain
// Riello domain personalization
if (window.location.hostname.includes("riellobess.")) {
  logo = require('@/assets/logo_riello.png');
}

const route = useRoute();

const title = ref(route.meta.title);
const description = ref("");

watch(() => route.path,
() => {
    title.value = route.meta.title;
    description.value = route.meta.description as string;
}
)

</script>

<style lang="scss">
@import "@/themes/generated/variables.base.scss";

.single-card {
  width: 100%;
  height: 100%;
  position: relative;

.imagen_logo {
  position: absolute;
  top: 32vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 300px;
  z-index: 1;
  .screen-x-small & {
      top: 24vh;
    }
}
  .dx-card {
    width: 330px;
    /* margin: auto auto; */
    padding: 40px;
    flex-grow: 0;
    position: absolute;
    top: 37vh;
    left: 50vw;
    transform: translate(-50%);
    

    .screen-x-small & {
      width: 100%;
      // height: 100%;
      border-radius: 0;
      box-shadow: none;
      border: 0;
      // flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}
</style>
