import "bootstrap/dist/css/bootstrap.min.css"; 
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import { createPinia } from 'pinia';
import themes from "devextreme/ui/themes";
import errorHandlingMixin from "./errors/errorHandlingMixin"
import App from "./App.vue";
import appInfo from "./app-info";
import esMessages from "devextreme/localization/messages/es.json" ;
import { locale, loadMessages } from "devextreme/localization";

import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license'; 

config({ licenseKey });  

themes.initialized(() => {
  loadMessages(esMessages);
  locale("es");
  const app = createApp(App);
  const pinia = createPinia();
  app.use(pinia);
  app.use(router);
  app.mixin(errorHandlingMixin); // Add the mixin to the app
  app.config.globalProperties.$appInfo = appInfo;
  app.mount('#app');
});
