const serverProtocol = window.location.protocol;
const serverHost = window.location.hostname;

let apiURL = `${serverProtocol}//${serverHost}/dirservice`
// let apiURL = 'http://localhost/dirservice'
let appURL = apiURL

// Set the API URL based on the app mode.
// const app_mode = process.env.NODE_ENV
// switch (app_mode) {
//   case 'development':
//     apiURL = 'https://nest-2.infitech.es/dirservice'
//     appURL = apiURL
//     break;
//   case 'production':
//     apiURL = 'https://gecko.infitech.es/dirservice'
//     appURL = apiURL
//     break;
// }

// Allow to override the API URL with an environment variable. For local development
if (process.env.VUE_APP_API_URL) {
  apiURL = process.env.VUE_APP_API_URL
  appURL = apiURL
}

export default {
  apiURL: () => apiURL,
  appURL: () => appURL,
  timeout: 1000,

  /**
   * Generates the authorization header object.
   * @param accessToken - The access token for authentication.
   * @returns The authorization header object.
   */
  authHeader (accessToken?: string) {
    if (accessToken) {
      // for Node.js Express back-end
      return { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-type': 'application/json'
      };
  } else {
      return { 
      'Content-type': 'application/json'
      };
  }
  },

  connData(extraConfig?: any, accessToken?: string): any {
      return {...{
          baseURL: this.apiURL(),
          headers: this.authHeader(accessToken),
      }, ...extraConfig}
  }
};

