import { RouterOptions, createRouter, createWebHashHistory } from "vue-router";
import { useAccountStore } from "./stores/account";

import defaultLayout from "./layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "./layouts/single-card.vue";

function loadView(view: string) {
  return () => import (/* webpackChunkName: "login" */ `./views/auth/${view}.vue`)
}

/**
 * Router options for configuring routes and history.
 */
const options: RouterOptions = {
  routes: [
  /**
   * MENU 
   */
    {
      path: "/sites",
      name: "data-grid-sites",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import(/* webpackChunkName: "Sites" */ "./views/data-grid-sites.vue")
    },
    {
      path: "/geolocation",
      name: "geolocation",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/site-map-view.vue")
    },
    {
      path: "/vehicle-battery",
      name: "vehicle-battery",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      // component: () => import(/* webpackChunkName: "Vehicle" */ "./views/vehicle-charger.vue")
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/vehicle-config-charger",
      name: "vehicle",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      // component: () => import("./views/car-page.vue")
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/settings-page.vue")
    },
    {

      path: "/informes",
      name: "informes",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/report/informes-page.vue")
    },
    {
      path: "/smart-billing",
      name: "Facturación inteligente",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/algorithms-ee",
      name: "Algoritmos EE",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/load-activation",
      name: "Activación de cargas",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/excedents-management",
      name: "Gestion de exdecendentes",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/energy-groups",
      name: "Grupos de energia",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/efficiency",
      name: "Eficiencia",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/export-data",
      name: "Exportación datos",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
        {
      path: "/domotica",
      name: "domotica",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    {
      path: "/climatization",
      name: "climatization",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/coming-soon-view.vue")
    },
    
/**
 * access to profile settings
 */
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/profile-page.vue")
    },
/**
 * access to dashboard of site
 */
    {
      path: "/site-dashboard/:siteId",
      name: "siteDashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: () => import(/* webpackChunkName: "Dashboard" */ "./views/siteDashboard.vue")
    },
    {
      path: "/site-dashboard-device/:siteId/:hubId",
      name: "site-dashboard-devices",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: () => import(/* webpackChunkName: "Dashboard" */ "./views/siteDashboard.vue")
    },
/**
 * Config battery of site
 */
    {
      path: "/battery/:siteId",
      name: "battery",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () =>  import(/* webpackChunkName: "Battery" */"./views/charge-battery.vue")
    },
/**
 * Edit site 
 */
    {
      path: "/site-form/:siteId",
      name: "site-form",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/site-edit-view.vue")
    },
/**
 * Alarms configuration of a site
 */
    {
      path: "/site-alarms-config/:siteId",
      name: "site-alarms-config",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/alarmConfig/alarm-config-dashboard.vue")
    },
    // update alarm config
    {
      path: "/site-alarms-config-form/:siteId/:alarmType",
      name: "site-alarms-config-form-edit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/alarmConfig/add-alarm-config-form.vue")
    },
/**
 * all devices of a site
 */
    {
      path: "/site-devices/:siteId",
      name: "site-devices",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/site-devices-page.vue")
    },
  /**
   * INFORMES
   */
    //access from dashboard with siteid
    {
      path: "/informes/:siteId",
      name: "informes-site",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/report/informes-page.vue")
    },
    {
      path: "/informe/:siteId",
      name: "user-report",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/report/user-report.vue")
    },
  /**
   * COMPANIES
   */
    {
      path: "/company-form",
      name: "company-form",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/add-company-form.vue")
    },
    // all users of company
    {
      path: "/user-dashboard/:companyId",
      name: "user-dashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/user-dashboard.vue")
    },
    // form to update a company
    {
      path: "/company-updates/:companyId",
      name: "company-update",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/add-company-form.vue")
    },
    // all sites that belong to a company
    {
      path: "/sites-company-dashboard/:companyId",
      name: "sites-company-dashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/sites-company-dashboard.vue")
    },
    // add new site to a company
    {
      path:"/add-site-company/:companyId",
      name:"add-site-company",
      meta:{
        requiresAuth:true,
        layout:defaultLayout
      },
      component:()=>import("./views/companies/add-site-company.vue")
    },
    // use this route to add new user in a company
    {
      path: "/add-user/:companyId",
      name: "add-user",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/user-management/add-user-form.vue")
    },
    // update user
    {
      path: "/user-updates/:userid",
      name: "user-updates",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/user-management/add-user-form.vue")
    },
    // all category to a company
    {
      path: "/category-dashboard/:companyId",
      name: "category-dashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('./views/companies/category-dashboard.vue')
    },
    // create new category for a company
    {
      path: "/add-category/:companyId",
      name: "add-category",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/add-category-form.vue")
    },
    // update category of a company
    {
      path: "/category-updates/:categoryId/:companyId",
      name: "category-updates",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/add-category-form.vue")
    },
    // list all companies
    {
      path: "/companies",
      name: "companies",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/companies/companies-dashboard.vue")
    },
    // list all users
    {
      path: "/users",
      name: "users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/user-management/user-grid.vue")
    },
    // create new user (witout company)
    {
      path: "/create-user",
      name: "create-user",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/user-management/add-user-form.vue")
    },
    // update user ( with companyid or not)
    {
      path:"/edit-user/:userid",
      name:"edit-user",
      meta:{
        requiresAuth:true,
        layout:defaultLayout
      },
      component:()=>import("./views/user-management/add-user-form.vue")
    },
/**
 * USER MANAGEMENT
 */
    //list all permissions over installation ( explicit and implicit )
    {
      path: "/permissions-over-installations/:userid",
      name: "permissions-over-installations",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/user-management/permissions-over-installations.vue")
    },
    //create new permission to a user for a site
    {
      path: "/add-permissions-user-form/:userId",
      name: "add-permissions-user-form",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import("./views/user-management/add-permissions-user-form.vue")
    },
  /**
   * LOGIN AND LOGOUT 
   */
    {
      path: "/load-session",
      name: "load-session",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reload Session"
      },
      component: loadView("load-session")
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Iniciar sesión"
      },
      component: loadView("login-form")
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/sites"
    },
    {
      path: "/recovery",
      redirect: "/sites"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/sites"
    },
  ],
  history: createWebHashHistory()
}
const router = createRouter(options);

router.beforeEach((to, from, next) => {

  const authStore = useAccountStore();

  // router points to a page that requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authStore.isSessionActive()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } 

  // router points to login page but user is already logged in
  else if (to.name === "login-form" && authStore.isSessionActive()) {
    // if user is already logged in, redirect to home
    next({ name: "data-grid-sites" });  
  }
  else{
    // if user is not logged in, redirect to login page
    next();
  }
  
});

export default router;
